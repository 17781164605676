<template>
  <v-dialog v-model="visible" persistent max-width="500px" scrollable>
    <v-card class="mx-auto" elevation="2" outlined>
      <v-card-title>
        Fetch album from Discogs
      </v-card-title>
      <v-card-subtitle>
        Provide a release-id or a barcode to search for a release on Discogs
      </v-card-subtitle>
      <v-card-text>
        <v-form ref="form" v-model="isValid" lazy-validation>
          <v-text-field v-model="releaseId" label="Release Id" :rules="rules.onlyNumbers"
                        hint="Example: 15311509" persistent-hint
          />
          <v-divider></v-divider>
          <v-text-field v-model="barcode" label="Barcode"/>
          <v-text-field v-model="catNo" label="Catalog No"/>
          <v-text-field v-model="year" label="Year" :rules="rules.onlyNumbers"/>
          <v-text-field v-model="country" label="Country"/>
          <v-text-field v-model="format" label="Format"
                        hint=" Examples: Vinyl, LP, Album, Limited Edition, Reissue, Promo"
                        persistent-hint
          />
        </v-form>
      </v-card-text>
      <v-snackbar v-model="snackbar" timeout="8000" :absolute=true>
        {{ snackbarMsg }}
        <template v-slot:action="{ attrs }">
          <v-btn depressed small v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <v-card-actions>
        <v-btn :disabled="!searchOK" @click="search"> Search</v-btn>
        <v-btn @click="cancel"> Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import albumServices from "@/services/albumService";

export default {
  name: "AlbumDiscogsDialog",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      snackbar: false,
      snackbarMsg: '',
      album: Object,
      releaseId: "",
      barcode: "",
      catNo: "",
      format: "",
      year: "",
      country: "",
      isValid: false,
      rules: {
        required: [value => !!value || "Field is required."],
        onlyNumbers: [
          v => (v === "" || /^\d+$/.test(v)) || 'This field only accept numbers'
        ],
      },
    }
  },
  computed: {
    searchOK() {
      return this.validate()
    }
  },
  methods: {
    search() {
      if (!this.validate()) {
        this.snackbarMsg = "At least a release id or a barcode has to be entered."
        this.snackbar = true
        return
      }
      this.isLoading = true
      albumServices.getAlbumFromDiscogs(this.releaseId, this.barcode, this.catNo, this.format, this.year, this.country)
          .then(response => {
            this.album = response.data;
            this.isLoading = false
            this.save();
          })
          .catch(error => {
            this.isLoading = false
            if (404 === error.response.status)
               this.snackbarMsg = "No release matching the search criteria."
            else {
              if (error.response.data)
                this.snackbarMsg = error.response.data
              else
               this.snackbarMsg = "Request failed. Reason= " + error.response.status + ": " + error.response.statusText
            }
            this.snackbar = true
          });
    },
    cancel() {
      this.$emit('discogs-cancelled');
    },
    save() {
      this.$emit('discogs-found', this.album);
    },
    validate() {
      return this.isValid && (this.releaseId !== "" || this.barcode !== "")
    },

  }
}
</script>

<style scoped>


</style>