<template>
  <v-card class="overflow-hidden">
    <v-app-bar dense elevation="0" absolute fixed>
      <v-toolbar-title>Albums: {{ albumsTotal }} fetched</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn rounded small @click="onNewAlbum()">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-text-field v-model="albumFilter.search_txt" prepend-inner-icon="mdi-magnify" label="Search" single-line
                    hide-details
                    @keyup.enter.native=newSearch></v-text-field>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn small @click="showAlbumFilter" v-bind="attrs" v-on="on">
            <v-icon>{{ filterIcon }}</v-icon>
          </v-btn>
        </template>
        <span>{{ filterTooltip }}</span>
      </v-tooltip>
      <v-btn small left :loading="isLoading" @click="newSearch()">
        <v-icon>mdi-run</v-icon>
      </v-btn>
    </v-app-bar>
    <AlbumFilterDialog :filter="albumFilter" :visible="isAlbumFilterVisible" @filter-save="onFilterSave"
                       @filter-close="onFilterCancelled">
    </AlbumFilterDialog>
    <AlbumDeleteDialog :albumListEntry="albumShort" :visible="isAlbumDeleteVisible" @deleted-album="onDeletedAlbum"
                       @delete-album-cancelled="onCancelledAlbum">
    </AlbumDeleteDialog>
    <AlbumEditDialog :albumListEntry="albumShort" :visible="isAlbumEditVisible" @edit-album-saved="onSavedAlbum"
                     @edit-album-cancelled="onCancelledAlbum" @edit-album-fetch-error="onFetchAlbumError">
    </AlbumEditDialog>
    <AlbumPublishDialog :albumListEntry="albumShort" :visible="isAlbumPublishVisible"
                        @sales-channels-changed="onSalesChannelChanged"
                        @sales-channels-cancel="onPublishCancel">
    </AlbumPublishDialog>
    <v-sheet class="overflow-y-auto" :height="albumAreaSizeY" v-resize="onResize">
      <v-container v-show="snackbar===true" dense no-gutters style="position: absolute; top:50px">
        <v-snackbar v-model="snackbar" timeout="8000" :absolute=true>
          {{ snackbarMsg }}
          <template v-slot:action="{ attrs }">
            <v-btn depressed small v-bind="attrs" @click="snackbar = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </v-container>
      <v-container id="album-area" class="overflow-y-auto" fluid style="position: relative; top:40px">
        <v-row v-show="albums.length === 0" dense no-gutters>
          <v-col cols="12">
            <v-alert color="lighten-2" style="text-align:center;">
              No albums to display
            </v-alert>
          </v-col>
        </v-row>
        <v-row dense no-gutters >
          <v-col v-for="album in albums" :key="album.id" :album="album" cols="12" md="4">
            <AlbumListCard :album="album" @edit-album="onEditAlbum" @delete-album="onDeleteAlbum"
                           @publish-album="onPublishAlbum"/>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <v-bottom-navigation v-show="albums.length>0" height="60" style="background: transparent">
      <v-pagination v-model="pagination.page" class="my-4" :total-visible="15" :length="totalPages" circle
                    @input="onPageChanged"
      ></v-pagination>
    </v-bottom-navigation>
  </v-card>
</template>

<script>

import AlbumFilterDialog from "@/components/album/AlbumFilterDialog";
import AlbumListCard from "@/components/album/AlbumListCard";
import AlbumDeleteDialog from "@/components/album/AlbumDeleteDialog";
import AlbumEditDialog from "@/components/album/AlbumEditDialog";
import AlbumPublishDialog from "@/components/album/AlbumPublishDialog";

export default {
  name: "AlbumPage",
  components: {AlbumFilterDialog, AlbumListCard, AlbumDeleteDialog, AlbumEditDialog, AlbumPublishDialog},
  data() {
    return {
      isLoading: false,
      snackbar: false,
      snackbarMsg: '',
      pagination: {
        page: 1,
      },
      search: '',
      action: '',
      currentItem: [],
      isAlbumFilterVisible: false,
      albumFilter: {
        on_stock: false,
        not_on_stock: false,
        out_of_print: false,
        second_hand: false,
        new: false,
        channel: {
          discogs: false,
          shopify: false,
        },
        format: "",
        search_field: null,
        search_txt: "",
      },
      isAlbumDeleteVisible: false, // display delete album dialog
      isAlbumEditVisible: false, // display edit album dialog
      albumShort: Object, // the album in the album list currently in work
      isAlbumPublishVisible: false, // display publish album dialog
      albumAreaSizeY: 0 // the area y size for displaying albums
    }
  },
  computed: {
    albums() {
      return this.$store.getters['albumStore/getAlbums']
    },
    albumsTotal() {
      return this.$store.getters['albumStore/getAlbumsTotal']
    },
    filterIcon() {
      // return icon name based if a filter criteria is set
      let icon = 'mdi-filter-off';
      Object.keys(this.albumFilter).forEach(k => {
        let value = this.albumFilter[k];
        if (value != null) {
          if (typeof (value) == 'boolean' && value === true)
            icon = 'mdi-filter'
          else if (typeof (value) === 'string' && value !== "")
            icon = 'mdi-filter'
          else {
            if (k === "channel") {
              if (value.discogs || value.shopify)
                icon = 'mdi-filter'
            }
          }
        }
      });
      return icon
    },
    filterTooltip() {
      let txt = '';
      Object.keys(this.albumFilter).forEach(k => {
        let value = this.albumFilter[k];
        if (value != null) {
          if (typeof (value) === 'boolean' && value === true)
            txt = txt + k + '|'
          else if (typeof (value) === 'string' && value !== "")
            txt = txt + value + '|'
          else {
            if (k === "channel") {
              if (value.discogs)
                txt = txt + "discogs" + '|'
              if (value.shopify)
                txt = txt + "shopify" + '|'
            }
          }
        }
      });
      if (txt === '')
        return 'No filter used'
      else
        return 'Filter: ' + txt;
    },
    pageSize() {
      return process.env.VUE_APP_ALBUM_PAGE_SIZE
    },
    totalPages() {
      let pages = this.albumsTotal/this.pageSize
      let rp = Math.round(pages)
      if (pages - rp > 0)
       return rp + 1
      else
        return rp
    }
  },
  methods: {
    showAlbumFilter() {
      this.isAlbumFilterVisible = true;
    },
    onFilterSave(filter) {
      this.albumFilter = filter;
      this.isAlbumFilterVisible = false;
    },
    onFilterCancelled() {
      this.isAlbumFilterVisible = false
    },
    onEditAlbum(album) {
      // open edit album dialog
      this.albumShort = album
      this.isAlbumEditVisible = true;
    },
    onDeleteAlbum(album) {
      this.albumShort = album
      this.isAlbumDeleteVisible = true
    },
    onDeletedAlbum(album) {
      this.isAlbumDeleteVisible = false
      this.snackbarMsg = "Album '" + album.artist + ": " + album.title + "' successfully deleted."
      this.snackbar = true
    },
    onNewAlbum() {
      // open edit album dialog for a new album
      this.albumShort = Object;
      this.isAlbumEditVisible = true;
    },
    onSavedAlbum(album) {
      // close edit album dialog and update list card
      this.albumShort.artist = album.artist
      this.albumShort.title = album.title
      this.albumShort.format = album.format
      this.albumShort.genre = album.genre
      this.albumShort.price = album.price
      this.isAlbumEditVisible = false;
    },
    onCancelledAlbum() {
      this.isAlbumEditVisible = false;
      this.isAlbumDeleteVisible = false;
    },
    onFetchAlbumError(err) {
      console.log("onFetchAlbumError(error):" + err)
      this.isAlbumEditVisible = false;
    },
    onPublishAlbum(album) {
      this.albumShort = album
      if (typeof this.albumShort.channel_items === 'undefined')
        this.$set(this.albumShort, 'channel_items', [])
      this.isAlbumPublishVisible = true;
    },
    onResize() {
      this.albumAreaSizeY =  window.innerHeight - 150
    },
    onSalesChannelChanged(channels) {
      this.albumShort.channel_items = channels
      this.isAlbumPublishVisible = false
    },
    onPublishCancel() {
      this.isAlbumPublishVisible = false
    },
    loadData() {
      this.isLoading = true;
      let filter = "";
      Object.entries(this.albumFilter).map(([key, val]) => {
        if ((typeof (val) == 'boolean' && val === true) || (typeof (val) == 'string' && Boolean(val)))
          filter = filter + key + "=" + val + "&"
        else {
          if (key === "channel") {
            // search in channels
            if (val["discogs"] || val["shopify"]) {
              let ca = "channel="
              if (val["discogs"])
                ca = ca + "discogs"
              if (val["shopify"]) {
                if (val["discogs"])
                  ca = ca + ","
                ca = ca + "shopify"
              }
              filter = filter + ca + "&"
            }
          }
        }
      });
      let args = {}
      args.filter = filter
      args.limit = process.env.VUE_APP_ALBUM_PAGE_SIZE
      args.skip = (this.pagination.page-1) * args.limit
      return this.$store.dispatch('albumStore/fetchAlbums', args).then(() => {
        this.isLoading = false
      }).catch(() => {
        this.isLoading = false
      })
    },
    newSearch() {
      this.pagination.page = 1
      this.loadData()
    },
    onPageChanged() {
      this.loadData()
    },
  },
  created() {
    this.page = 1
    this.$store.dispatch('albumStore/fetchGenres')
    this.$store.dispatch('albumStore/fetchConditions')
    this.$store.dispatch('albumStore/fetchFormats')
  },
  mounted () {
    this.onResize()
    this.page = 1
    this.$store.dispatch('albumStore/clearAlbums')
  },
}

</script>

<style scoped>

</style>

