<template>
  <v-card width="400" class="mx-auto, mb-2">
    <div class="d-flex flex-no-wrap">
      <div class="text-area">
        <v-card-title class="album-title">{{ album.title }}</v-card-title>
        <v-card-subtitle v-text="album.artist"></v-card-subtitle>
      </div>
      <v-avatar class="ma-3" size="80px" tile>
        <v-img :src="album.image_url" lazy-src="../../assets/images/vinyl.png"></v-img>
      </v-avatar>
    </div>
    <v-card-text class="mt-0 pt-0 pb-0" >
      <v-row dense>
        <v-col cols="12">
          Price: {{ album.price }}
        </v-col>
        <v-col cols="12">
          Format: {{ album.format }}
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="6">
          Genre: {{ album.genre }}
        </v-col>
        <v-col cols="6" style="text-align:end;">
          <v-chip v-for="item in album.channel_items" v-bind:key="item.channel" small pill class="ml-1">{{item.channel}}</v-chip>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="pt-0">
      <v-btn class="ml-2 mt-3" fab icon height="40px" right width="40px" @click="editAlbum()">
        <v-icon>mdi-pencil-outline</v-icon>
      </v-btn>
      <v-btn class="ml-2 mt-3" fab icon height="40px" right width="40px" @click="deleteAlbum()">
        <v-icon>mdi-trash-can-outline</v-icon>
      </v-btn>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="ml-2 mt-3" fab icon height="40px" right width="40px" @click="publishAlbum()"
                 v-bind="attrs"
                 v-on="on">
            <v-icon>mdi-publish</v-icon>
          </v-btn>
        </template>
        <span>Sales channel publish</span>
      </v-tooltip>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
  props: {
    album: Object
  },
  data: () => ({
    show: false,
  }),
  methods: {
    editAlbum() {
      this.$emit("edit-album", this.album);
    },
    deleteAlbum() {
      this.$emit("delete-album", this.album);
    },
    publishAlbum() {
      this.$emit("publish-album", this.album);
    }
  }
}
</script>

<style scoped>
.album-title {
  font-size: medium;
  min-width: 0;
}

.text-area {
  display: block;
  width: 300px;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
