<template>
  <v-dialog v-model="visible" persistent max-width="600px" scrollable>
    <v-card>
      <v-toolbar dense>
        <v-btn icon @click="cancel()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title> {{ cardTitle }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-show="isNewAlbum" text @click="showDiscogsDlog" v-bind="attrs" v-on="on">
              Fetch from Discogs
            </v-btn>
          </template>
          <span>Initialize new album using Discogs.</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text @click="cancel()" v-bind="attrs" v-on="on">
              <v-icon>mdi-cancel</v-icon>
            </v-btn>
          </template>
          <span>Cancel edit operation. Changes will not be saved.</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text @click="save()" v-bind="attrs" v-on="on">
              <v-icon>mdi-content-save-all</v-icon>
            </v-btn>
          </template>
          <span>Save changes into database.</span>
        </v-tooltip>
        <template v-slot:extension>
          <v-tabs v-model="albumTab">
            <v-tabs-slider color="pink"></v-tabs-slider>
            <v-tab href='#album'>Album</v-tab>
            <v-tab href='#sales'>Sales</v-tab>
            <v-tab href='#media'>Media</v-tab>
            <v-tab href='#cover'>Cover</v-tab>
            <v-tab href='#various'>Various</v-tab>
          </v-tabs>
        </template>
      </v-toolbar>
      <v-card-text style="height: 600px;">
        <v-form ref="form">
          <v-tabs-items v-model="albumTab">
            <v-tab-item value="album">
              <v-container>
                <v-row dense>
                  <v-col cols="12">
                    <v-text-field dense v-model="albumDetail.id" readonly label="ID">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field dense v-model="albumDetail.artist" :rules="rules.required">
                      <template #label>
                        <span class="red--text"><strong>* </strong></span>Artist
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field dense v-model="albumDetail.title" :rules="rules.required">
                      <template #label>
                        <span class="red--text"><strong>* </strong></span>Title
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-select v-model="albumDetail.format" :items="formats" dense :rules="rules.required">
                      <template #label>
                        <span class="red--text"><strong>* </strong></span>Format
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-combobox dense v-model="albumDetail.genre" :items="genres" :rules="rules.required">
                      <template #label>
                        <span class="red--text"><strong>* </strong></span>Genre
                      </template>
                    </v-combobox>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field dense v-model.number="albumDetail.released" label="Released" :rules="rules.number">
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="6">
                    <v-checkbox dense v-model="albumDetail.out_of_print" label="Out of Print" color="white"></v-checkbox>
                  </v-col>
                  <v-col cols="6">
                    <v-checkbox dense v-model="albumDetail.second_hand" label="Second Hand" color="white"></v-checkbox>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12">
                    <v-text-field dense v-model.number="albumDetail.release_id"
                                  label="Release Id (Discogs)"
                                  hint="A release id is needed to publish an album to Discogs."
                                  persistent-hint
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
            <v-tab-item value="sales">
              <v-container>
                <v-row dense>
                  <v-col cols="6">
                    <v-text-field dense v-model.number="albumDetail.price" :rules="rules.required">
                      <template #label>
                        <span class="red--text"><strong>* </strong></span>Price
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field dense v-model.number="albumDetail.old_price" label="Old Price"
                                  @change='onCheckNumberEmpty($event, "old_price")'
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="6">
                    <v-text-field dense v-model.number="albumDetail.cost" label="Cost"></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field dense v-model.number="albumDetail.weight" label="Weight [g]"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field dense v-model.number="albumDetail.stock_qty" label="Stock Quantity"></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
            <v-tab-item value="media">
              <v-container>
                <v-row dense>
                  <v-col cols="12">
                    <v-select dense v-model="albumDetail.condition" label="Media Condition"
                              :items="mediaConditions"
                              item-value="value"
                              item-text="text"
                              clearable
                              :rules="rules.condition"
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-select dense v-model="albumDetail.sleeve_condition" label="Sleeve Condition"
                              :items="sleeveConditions"
                              item-value="value"
                              item-text="text"
                              clearable
                              :rules="rules.condition"
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea dense outlined clearable clear-icon="mdi-close-circe"
                                v-model="albumDetail.notes"
                                value="filled- filled example" label="Notes"></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
            <v-tab-item value="cover">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <div class="subtitle-1">
                      Provide a link to an existing cover image. If none is available go to
                      <a target="_blank"
                         href="https://heavy-weight-music.myshopify.com/admin/settings/files?query=&order=key+asc">Shopify
                        file</a>
                      upload an image and copy the url in the field below.
                    </div>
                    <v-divider></v-divider>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12">
                    <v-text-field dense v-model="albumDetail.image_url" label="Cover Link"></v-text-field>
                  </v-col>
                  <v-col cols="12" class="d-flex justify-center">
                    <v-avatar class="ma-3" size="250px" tile>
                      <v-img :src="albumDetail.image_url" lazy-src="../../assets/images/vinyl.png"></v-img>
                    </v-avatar>
                  </v-col>
                </v-row>
                <!-- no upload function implemented todo -> check for simple, cost effectiv solution
                <v-row dense>
                  <v-col cols="9">
                    <v-file-input dense accept="image/png, image/jpeg, image/jpg, image/bmp"  label="Cover" truncate-length="15"
                                  placeholder="Upload an album cover"
                                   chips small-chips show-size :rules="rules.coverSize">
                    </v-file-input>
                    </v-col>
                    <v-col cols="3">
                        <v-btn @click="onUploadImage()" >
                          Upload file
                        </v-btn>
                  </v-col>
                </v-row>
                <!-->
              </v-container>
            </v-tab-item>
            <v-tab-item value="various">
              <v-container>
                <v-row dense>
                  <v-col cols="6">
                    <v-text-field dense readonly v-model="albumDetail.created_at" label="Created At"></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field dense readonly v-model="albumDetail.updated_at" label="Last Update"></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
          </v-tabs-items>
        </v-form>
      </v-card-text>
      <v-snackbar v-model="snackbar" timeout="8000" :absolute=true>
        {{ snackbarMsg }}
        <template v-slot:action="{ attrs }">
          <v-btn depressed small v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <AlbumDiscogsDialog :visible="isDiscogsDlogVisible" @discogs-found="onFoundDiscogsRelease"
                          @discogs-cancelled="onDiscogsCancelled">
      </AlbumDiscogsDialog>
    </v-card>
  </v-dialog>
</template>

<script>

import cloneDeep from 'lodash/cloneDeep'
import AlbumDiscogsDialog from "@/components/album/AlbumDiscogsDialog";
//import {deepEqual} from "vuetify/src/util/helpers";

export default {
  name: "AlbumEditDialog",
  components: {AlbumDiscogsDialog,},
  props: {
    albumListEntry: {}, // an album(short) object passed from Component
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      snackbar: false,
      snackbarMsg: '',
      albumDetail: Object,
      isLoading: false,
      isNewAlbum: false,
      isDiscogsDlogVisible: false,
      selectedChannel: {},
      albumTab: null,
      rules: {
        required: [value => !!value || "Field is required."],
        number: [v => (v === "" || (!isNaN(parseFloat(v)) && v >= 0 && v <= 9999)) || 'Number has to be between 0 and 999'],
        coverSize: [v => !v || v.size < 100000 || 'Cover image size should be less than 100kb!'],
        condition: [v => this.isNewItem || (!!v && !this.isNewItem) || 'Media/Sleeve Condition is required for 2nd hand album'],
      },
    }
  },
  computed: {
    isNewItem() {
      return !this.albumDetail.second_hand
    },
    cardTitle() {
      return this.isNewAlbum ? 'New Album' : 'Edit Album';
    },
    genres() {
      return this.$store.getters['albumStore/getGenres']
    },
    mediaConditions() {
      return this.$store.getters['albumStore/getMediaConditions']
    },
    sleeveConditions() {
      return this.$store.getters['albumStore/getSleeveConditions']
    },
    formats() {
      return this.$store.getters['albumStore/getFormats']
    }
  },

  methods: {
    save() {
      if (!this.validate()) {
        this.snackbarMsg = "Please check if all fields are valid."
        this.snackbar = true
        return
      }
      // save changes and return changed album
      if (this.isNewAlbum) {
        return this.$store.dispatch('albumStore/createAlbum', this.albumDetail).then(() => {
          this.albumDetail = this.$store.getters['albumStore/getAlbum']
          let albumShort = {}
          albumShort.artist = this.albumDetail.artist
          albumShort.title = this.albumDetail.title
          albumShort.format = this.albumDetail.format
          albumShort.genre = this.albumDetail.genre
          albumShort.price = this.albumDetail.price
          this.$emit("edit-album-saved", albumShort);
        }).catch((error) => {
          console.log("Error creating album. Reason=" + error)
          if (error.response.data)
            this.snackbarMsg = error.response.data
          else
            this.snackbarMsg = "Error creating album. Reason=" + error.response.status + ": " + error.response.statusText
          this.snackbar = true
        })
      } else {
        return this.$store.dispatch('albumStore/updateAlbum', this.albumDetail).then(() => {
          this.albumDetail = this.$store.getters['albumStore/getAlbum']
          let albumShort = cloneDeep(this.albumListEntry);
          albumShort.artist = this.albumDetail.artist
          albumShort.title = this.albumDetail.title
          albumShort.format = this.albumDetail.format
          albumShort.genre = this.albumDetail.genre
          albumShort.price = this.albumDetail.price
          this.$emit("edit-album-saved", albumShort);
        }).catch((error) => {
          console.log("Error updating album" + error)
          if (error.response.data)
            this.snackbarMsg = error.response.data
          else
            this.snackbarMsg = "Error updating album. Reason=" + error.response.status + ": " + error.response.statusText
          this.snackbar = true
        })
      }
      // return changes to parent
    },
    cancel() {
      this.$emit('edit-album-cancelled');
    },
    fetchAlbum(id) {
      this.isLoading = true
      return this.$store.dispatch('albumStore/fetchAlbum', id).then(() => {
        this.albumDetail = this.$store.getters['albumStore/getAlbum']
        this.isLoading = false
      }).catch((error) => {
        console.log("Error fetching detail::" + error)
        this.cancel()
      })
    },
    showDiscogsDlog() {
      this.isDiscogsDlogVisible = true
    },
    onFoundDiscogsRelease(album) {
      this.albumDetail = album
      this.albumDetail.stock_qty = 1
      this.isDiscogsDlogVisible = false
    },
    onDiscogsCancelled() {
      this.isDiscogsDlogVisible = false
    },
    onCheckNumberEmpty(value, field) {
      if (!value.trim()) {
        this.albumDetail[field] = null;
      }
    },
    onUploadImage() {

    },
    validate() {
      if (this.$refs.form.validate()) {
        return true
      }
    },

  },

  watch: {
    visible(flag) {
      // check when dialog was opened, save the album entry and fetch the album details
      if (flag) {
        this.albumTab = 0
        if (this.albumListEntry.id) {
          this.isNewAlbum = false;
          this.fetchAlbum(this.albumListEntry.id)
        } else {
          this.isNewAlbum = true
          this.albumDetail = {
            stock_qty: 1,
            second_hand: false,
            condition: "",
            sleeve_condition: "",
            image_url: "../assets/images/vinyl.png" // set image to avoid displaying a previous one
          }
          this.selectedChannel = {}
          if (this.$refs.form)
            this.$refs.form.reset()
        }
      }
    }
  }
}
</script>


<style scoped>
.white--text {
  color: white;
}
</style>