<template>
  <v-dialog v-model="visible" persistent max-width="400px" scrollable>
    <v-card>
      <v-toolbar dense>
        <v-btn icon @click="cancel()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title> {{ cardTitle }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text @click="cancel()" v-bind="attrs" v-on="on">
              <v-icon>mdi-cancel</v-icon>
            </v-btn>
          </template>
          <span>Cancel delete operation. The album is not deleted.</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="warn" text @click="deleteAlbum()" v-bind="attrs" v-on="on">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
          <span>Remove album permanently from the database.</span>
        </v-tooltip>
      </v-toolbar>
      <v-card-text style="height: 600px;">
        <v-container>
          <v-row dense>
            <v-col cols="12">
              <v-text-field dense v-model="albumListEntry.id" readonly disabled label="ID">
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field dense v-model="albumListEntry.artist" readonly label="Artist">
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field dense v-model="albumListEntry.title" readonly label="Title">
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-checkbox dense v-model="deleteOnChannels" label="Delete also on sales channel?"></v-checkbox>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-snackbar v-model="snackbar" timeout="8000" :absolute=true>
        {{ snackbarMsg }}
        <template v-slot:action="{ attrs }">
          <v-btn depressed small v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AlbumDeleteDialog",
  components: {},
  props: {
    albumListEntry: {}, // an album(short) object passed from Component
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      deleteOnChannels: false,
      snackbar: false,
      snackbarMsg: '',
      deletedAlbum: Object,
    }
  },
  computed: {
    cardTitle() {
      return 'Delete Album';
    },
  },

  methods: {
    deleteAlbum() {
      let arg = {
        album_id: this.albumListEntry.id,
        deleteOnChannels: this.deleteOnChannels
      }
      this.deletedAlbum=  this.albumListEntry
      return this.$store.dispatch('albumStore/deleteAlbum', arg).then(() => {
        console.log("Album deleted")
        this.$emit("deleted-album", this.deletedAlbum);
      }).catch((error) => {
        console.log("Error deleting album" + error)
        this.snackbarMsg = "Error: "+error.msg
        this.snackbar = true
      })
    },
    cancel() {
      this.$emit('delete-album-cancelled');
    },
  },

  watch: {
    visible() {
      // check when dialog was opened, save the album entry and fetch the album details
    }
  }
}
</script>


<style scoped>

</style>