<template>
  <v-dialog v-model="visible" persistent max-width="400px" scrollable @keydown.esc="cancel" @keydown.enter="save" tabindex="0" >
    <v-card>
      <v-toolbar>
        <v-btn icon @click="cancel()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Album Selector</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn text @click.stop="clear()"> Clear</v-btn>
        </v-toolbar-items>
        <v-toolbar-items>
          <v-btn text @click="save()" @keyup.enter.native=save>Save</v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text style="height: 400px;">
        <v-list two-line dense>
          <v-subheader>Album Filter</v-subheader>
          <v-list-item>
            <v-list-item-action>
              <v-simple-checkbox :ripple="false" v-model="localFilter.on_stock"></v-simple-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>On Stock</v-list-item-title>
              <v-list-item-subtitle>List only albums which are on stock</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-simple-checkbox :ripple="false" v-model="localFilter.not_on_stock"></v-simple-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Not on Stock</v-list-item-title>
              <v-list-item-subtitle>List only albums which are not on stock</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-simple-checkbox :ripple="false" v-model="localFilter.out_of_print"></v-simple-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Out Of Print</v-list-item-title>
              <v-list-item-subtitle>List only albums which are out of print</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-simple-checkbox :ripple="false" v-model="localFilter.second_hand"></v-simple-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Second Hand</v-list-item-title>
              <v-list-item-subtitle>List only used albums</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-simple-checkbox :ripple="false" v-model="localFilter.new"></v-simple-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>New Album</v-list-item-title>
              <v-list-item-subtitle>List only new albums</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list one-line>
            <v-list-item>
              <v-list-item-action>
                <v-select dense clearable v-model="localFilter.format" :items="mediaFormats" label="Media formats ..."
                ></v-select>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-list>
        <v-divider></v-divider>
        <v-list two-line dense>
          <v-subheader>Channel Filter</v-subheader>
            <v-list-item>
              <v-list-item-action>
                <v-simple-checkbox :ripple="false" dense v-model="localFilter.channel.discogs"></v-simple-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Discogs</v-list-item-title>
                <v-list-item-subtitle>List only albums sold on Discogs</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-action>
                <v-simple-checkbox :ripple="false" dense v-model="localFilter.channel.shopify"></v-simple-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Shopify</v-list-item-title>
                <v-list-item-subtitle>List only albums sold on Shopify</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list one-line>
          <v-list-item>
            <v-list-item-action>
              <v-select dense clearable v-model="localFilter.search_field" :items="searchFields" label="Search text only in ..."
              ></v-select>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AlbumFilterDialog",
  props: {
    filter: Object, // receive current filter from parent
    visible: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    localFilter: {
      on_stock: false,
      not_on_stock: false,
      out_of_print: false,
      second_hand: false,
      new: false,
      channel: {
        discogs: false,
        shopify: false,
      },
      format: "",
      search_field: null,
      search_txt: "",
    }, // work with a local copy

    searchFields: [{text: 'Artist', value: 'artist'}, {text: 'Title', value: 'title'},
      { text: 'Description',value: 'description'}, { text: 'ID',value: '_id'}],
    mediaFormats: [ {text: 'CD', value: 'CD'},
      { text: 'LP',value: 'LP'},  { text: '7inch',value: '7inch'},{ text: '10inch',value: '10inch'}],
  }),
  methods: {
    save() {
      // return the current filter setting to caller
      this.$emit("filter-save", this.localFilter);
    },
    cancel() {
      this.$emit('filter-close');
    },
    clear() {
      // clear all filter values
      Object.keys(this.localFilter).forEach(k => {
            let value = this.localFilter[k];
            if (value != null) {
              if (typeof (value) == 'boolean')
                this.localFilter[k] = false;
              else if (typeof (value) == 'string')
                this.localFilter[k] = null;
              else {
                if (k === "channel") {
                  value.discogs = false
                  value.shopify = false
                }
              }
            }
          }
      )
    },
  },
  watch: {
    visible(flag) {
      // check when dialog was opened and copy the filter to a local copy to work with
      if (flag) {
        this.localFilter = Object.assign({}, this.filter);
      }
    }
  }
}
</script>


<style scoped>

</style>