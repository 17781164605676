<template>
  <v-dialog v-model="visible" persistent max-width="600px" scrollable>
    <v-card>
      <v-toolbar dense>
        <v-btn icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Sales Channels</v-toolbar-title>
        <v-spacer></v-spacer>
        <template v-slot:extension>
          <v-tabs v-model="channelTab">
            <v-tab v-for="item in salesChannelNames" :key="item"
                   @click="onChannelSelected(item)"> {{ item }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>
      <v-card-text style="height: 600px;">
        <v-container>
          <v-row dense>
            <v-col cols="6">
              <v-btn v-if="selectedChannel.item_id == null" left :loading="isLoading" @click="publish()">
                Publish to {{ selectedChannel.channel }}
              </v-btn>
              <v-btn v-if="selectedChannel.item_id != null" left :loading="isLoading" @click="unPublish()">
                Unpublish from {{ selectedChannel.channel }}
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn v-if="selectedChannel.item_id != null" left :href="albumChannelLink" target="_blank">
                Show album in sales channel
              </v-btn>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-text-field disabled dense v-model="selectedChannel.item_id" label="Id"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field disabled dense v-model="selectedChannel.variant_id" label="Variant"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-checkbox color="white" dense v-model="selectedChannel.published" label="Published (Uncheck to publish in draft mode)">
              </v-checkbox>
            </v-col>
            <v-col cols="12">
              <v-text-field disabled dense v-model="selectedChannel.stock_qty" label="Stock Quantity"></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="6">
              <v-text-field disabled dense v-model="selectedChannel.created_at" label="Created At"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field disabled dense v-model="selectedChannel.updated_at" label="Updated At"></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-snackbar v-model="snackbar" timeout="6000" :absolute=true>
        {{ snackbarMsg }}
        <template v-slot:action="{ attrs }">
          <v-btn depressed small v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </v-dialog>
</template>

<script>

//import {deepEqual} from "vuetify/src/util/helpers";

export default {
  name: "AlbumPublishDialog",
  components: {},
  props: {
    albumListEntry: {}, // an album object passed from Component
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      snackbar: false,
      snackbarMsg: "Gaga hello",
      changeFlag: false, // mark if any update has been performed
      isLoading: false,
      albumDetail: Object,
      channelTab: 1,
      selectedChannel: {}, // channel currently visible
    }
  },
  computed: {
    albumChannelLink() {
      if (this.selectedChannel.channel === "shopify") {
        return "https://heavy-weight-music.myshopify.com/admin/products/"+this.selectedChannel.item_id
      }
      if (this.selectedChannel.channel === "discogs") {
        return "https://www.discogs.com/sell/item/" + this.selectedChannel.item_id
      }
      return ""
    },
    salesChannelNames() {
      // return list of supported sales channels
      return this.$store.getters['albumStore/getSalesChannels']
    },
  },

  methods: {

    publish() {
      // publish album to selected channel
      if ( this.selectedChannel.channel === "discogs" && !this.albumDetail.release_id) {
        this.snackbar = true
        this.snackbarMsg = "To publish to Discogs a release id is needed."
        return
      }
      this.selectedChannel.album_id = this.albumDetail.id
      this.isLoading = true
      return this.$store.dispatch('albumStore/publishChannel', this.selectedChannel).then(() => {
        this.albumDetail = this.$store.getters['albumStore/getAlbum']
        this.onChannelSelected(this.salesChannelNames[this.channelTab])
        this.isLoading = false
        this.changeFlag = true
        this.snackbarMsg = "Successfully published album to " + this.selectedChannel.channel
        this.snackbar = true
      }).catch((error) => {
        this.isLoading = false
        if (error.response.data)
          this.snackbarMsg = error.response.data
        else
          this.snackbarMsg = "Error publish album. Reason=" + error.response.status + ": " + error.response.statusText
        this.snackbar = true
      })
    },

    unPublish() {
      // remove album from selected channel
      this.isLoading = true
      this.selectedChannel.album_id = this.albumDetail.id
      return this.$store.dispatch('albumStore/unPublishChannel', this.selectedChannel).then(() => {
        this.albumDetail = this.$store.getters['albumStore/getAlbum']
        this.onChannelSelected(this.salesChannelNames[this.channelTab])
        this.isLoading = false
        this.changeFlag = true
        this.snackbarMsg = "Successfully removed album from " + this.selectedChannel.channel
        this.snackbar = true
      }).catch((error) => {
        this.isLoading = false
        this.snackbar = true
        this.snackbarMsg = "Error un-publish album from channel " + this.selectedChannel.channel + "Reason=" + error
      })
    },
    close() {
      this.snackbar = false
      if (this.changeFlag)
        this.$emit('sales-channels-changed', this.albumDetail.channel_items);
      else
        this.$emit('sales-channels-cancel');
    },

    onChannelSelected(channelName) {
      this.selectedChannel = null
      if (this.albumDetail.channel_items != null && this.albumDetail.channel_items.length > 0) {
        // one or more channels already defined
        this.selectedChannel = this.albumDetail.channel_items.find(element => element.channel === channelName)
      }
      if (this.selectedChannel == null) {
        this.selectedChannel = {channel: channelName}
        this.selectedChannel.published = true
      }
    },
    fetchAlbum(id) {
      this.isLoading = true
      return this.$store.dispatch('albumStore/fetchAlbum', id).then(() => {
        this.albumDetail = this.$store.getters['albumStore/getAlbum']
        this.isLoading = false
        this.onChannelSelected(this.salesChannelNames[this.channelTab])
      }).catch((error) => {
        console.log("Error fetching detail::" + error)
        this.isLoading = false
        this.close()
      })
    },

  },
  watch: {
    visible(flag) {
      // check when dialog was opened, save the album entry and fetch the album details
      if (flag) {
        this.channelTab = 1 //
        this.selectedChannel = {}
        this.fetchAlbum(this.albumListEntry.id)
      }
    },

  }
}
</script>


<style scoped>

</style>